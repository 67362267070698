import React, { useState } from 'react'
import cntcfirstimg from '../../../images/plast.jpg'
import cntcfirstimg2 from '../../../images/plast2.jpg'

function Section1plast() {

    const [showMore, setShowMore] = useState(false);
    const [showLess, setshowLess] = useState(true);
    const [showMoree, setShowMoree] = useState(false);
    const [showLesss, setshowLesss] = useState(true);

    const toggleshowMore = () => {
        setShowMore(true);
        setshowLess(false)
    };
    const toggleshowLess = () => {
        setShowMore(false);
        setshowLess(true)
    };
    const toggleshowMoree = () => {
        setShowMoree(true);
        setshowLesss(false)
    };
    const toggleshowLesss = () => {
        setShowMoree(false);
        setshowLesss(true)
    };
    return (
        <div className=''>
            <div className='row g-0'>
                <div className='col-md-6 bgcolordark p-5'>

                    <div className='p-md-5'>
                        <div className='pe-md-5'>
                            <div className='d-flex align-items-center'>
                                <span className='borderline'></span>
                                <h1 className='ms-3'>Për Ne</h1>
                            </div>
                            <p className='paragraphforabout pe-md-5 mt-4'>
                                Distributor eksluziv i profilit te PVC-se se cilësisë së lartë - Xharra Plast, partneri Juaj për zgjedhjen më të mirë”
                                Xharra Plast është një emër i njohur në treg për cilësi të lartë në fushën e profilave te PVC-se. Ne kemi bërë të mundur
                                që profilat e PVC-se të jenë një zgjedhje e preferuar për projektet e ndërtimit në Kosovë, Shqipëri, Gjermani, Zvicer dhe
                                shume vende tjera te Europes. Si distributor ekskluziv i profilave te PVC-se VEKA, me cilësi të kategorisë A dhe të
                                profilave të PVC-se Enderpen, ne jemi burimi juaj i besueshëm për produkte të cilësisë së lartë.
                                Përse zgjidhni profilat PVC të Xharra Plast:
                                <br />
                                <br />
                                Cilësi e garantuar: Profili PVC VEKA nga Xharra Plast është i njohur për cilësinë e tij të lartë dhe rezistencën e tij ndaj
                                kushteve të ndryshueshme të mjedisit. Ne ofrojmë profile PVC-se per të siguruar qe projektet tuaja te kene cilësi të
                                pandryshueshme per shume vite.
                                <br />
                                <br />
                                Asortimenti më i gjerë: Ne kemi në dispozicion të gjitha llojet e materialeve dhe aksesoreve për PVC, duke përfshirë
                                profilet, mekanizmat, hekurin, dorezat, dhe shumë të tjera.
                                <br />
                                <br />
                                Ekspertiza dhe këshillimi: Ekipi ynë i kualifikuar është gjithmonë në dispozicion për t'ju ofruar këshillim dhe ndihmë në
                                zgjedhjen e problemeve të duhura për projektin tuaj gjate prodhimit te PVC. Ne kuptojmë nevojat e prodhimit dhe jemi
                                këtu për të ndihmuar në çdo hap të rrugës.
                                <br />
                                <br />

                                {showMore && !showLess && (
                                    <div>
                                        Në Xharra Plast, ne kemi një vizion për të promovuar prodhimin e cilësisë së lartë te dritareve në Kosovë dhe Shqipëri si
                                        dhe ne vendet e rajonit dhe Europe. Dritarja nga PVC është një komponent kyç i çdo ndërtimi, dhe ne jemi këtu për të
                                        siguruar që ju keni produktet dhe shërbimet e nevojshme për të arritur suksesin e dëshiruar në projektet tuaja.
                                        Nëse keni pyetje ose dëshironi të mësoni më shumë rreth profileve PVC dhe zgjidhjeve tona të tjera për prodhim, mos
                                        ngurroni të na kontaktoni. Xharra Plast - Profili PVC i cilësisë së lartë për prodhimet Tuaja të suksesshme.
                                    </div>
                                )}

                            </p>
                            {!showMore && (
                                < div >
                                    <div className='d-flex align-items-center readmorebtn'>
                                        <button className='' onClick={toggleshowMore} >Read More</button>
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div></div>
                            )}

                            {showMore && (
                                < div >
                                    <div className='d-flex align-items-center readless' >
                                        <button onClick={toggleshowLess}>Read Less</button >
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                        </svg>
                                    </div></div>
                            )}

                        </div>
                    </div>

                </div>
                <div className='col-md-6 bgcolorwhite p-5'>
                    <div className='p-md-5'>
                        <img className='mx-auto w-75' src={cntcfirstimg} />
                    </div>
                </div>
                <div className='col-md-6 bgcolorwhite p-5'>

                    <div className='p-md-5'>
                        <img className='mx-auto w-75' src={cntcfirstimg2} />
                    </div>
                </div>
                <div className='col-md-6 bgcolordark p-5'>

                    <div className='p-md-5'>
                        <div className='pe-md-5'>
                            <div className='d-flex align-items-center'>
                                <span className='borderline'></span>
                                <h1 className='ms-3'>Historiku</h1>
                            </div>
                            <p className='paragraphforabout pe-md-5 mt-4'>
                                Kompania Xharra Plast është një ndërmarrje private e njohur në tregun e profilave të PVC-së. Historia e
                                saj është një rrugëtim i vlefshëm dhe i suksesshëm që ka filluar para 24 vitesh. Në këto mbi dy dekada,
                                kompania ka arritur të bëhet një emër i njohur dhe i respektuar në treg për cilësinë e lartë të produktit
                                të saj dhe për angazhimin ndaj klientëve.
                                <br />
                                <br />
                                Fillimisht, Xharra Plast filloi si një kompani e vogël, por me vizion të madh. Me punën dhe përkushtimin
                                e ekipit të saj, firma gradualishte ka rritur dhe tani punëson rreth 50 punëtorë. Ky rritje gradual tregon
                                suksesin dhe qëndrueshmërinë e saj në treg.
                                <br />
                                <br />
                                Një prej elementeve që ka bërë të mundur këtë rritje të ndjeshme është reputacioni i saj për korrektësi.
                                Klientët preferojnë Xharra Plast për shkak të mënyrës së saj korrekte të punës dhe angazhimit të saj ndaj
                                nevojave të klientëve. Ky besim i ndërtuar u kurorezua me partneritetin e saj me kompaninë prestigjioze
                                VEKA nga Gjermania, emrin më të njohur në industrinë e profilave të PVC-së.
                                <br />
                                <br />
                                Xharra Plast ka arritur të hapë depo në qytete të ndryshme te Kosoves duke përfshirë Prizrenin, Ferizajn,
                                Prishtinën, nënfiljalet ne Peje dhe Gjakove si dhe perfaqesi në Shqiperi ne qytetin e Tiranës, duke e bërë
                                që produktet e saj të jenë më të lehta të arritshme për klientët në këto rajone. Kjo strategji e zhvillimit
                                të biznesit tregon përkushtimin te saj për të shërbyer klientët në mënyrë më efikase dhe të ofrojë
                                shërbim më të mirë.
                                <br />
                                <br />
                                {showMoree && !showLesss && (
                                    <div>
                                        Me ekipet profesionale dhe aftësinë për të adresuar çdo lloj sfide, Xharra Plast ka arritur që nder vite të
                                        rrisë qarkullimet e saja dhe të sigurojë cilësi të lartë në produktet e saj. Kjo është një histori e suksesit të
                                        vërtetë që ilustron përpjekjet dhe përkushtimin e një kompanie të vogël që ka arritur të bëhet një aktor i
                                        rëndësishëm në tregun e profilave të PVC-së.
                                    </div>
                                )}

                            </p>

                            {!showMoree && (
                                < div >
                                    <div className='d-flex align-items-center readmorebtn'>
                                        <button className='' onClick={toggleshowMoree} >Read More</button>
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div></div>
                            )}

                            {showMoree && (
                                < div >
                                    <div className='d-flex align-items-center readless' >
                                        <button onClick={toggleshowLesss}>Read Less</button >
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                        </svg>
                                    </div></div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1plast