import React from 'react'
import cntcfirstimg from '../../../images/towers.jpg'
import cntcfirstimg2 from '../../../images/towers2.jpg'

function Section1towers() {
    return (
        <div className=''>
            <div className='row g-0'>
                <div className='col-md-6 bgcolordark p-5'>

                    <div className='p-md-5'>
                        <div className='pe-md-5'>
                            <div className='d-flex align-items-center'>
                                <span className='borderline'></span>
                                <h1 className='ms-3'>Historiku</h1>
                            </div>
                            <p className='paragraphforabout pe-md-5 mt-4'>
                                Në vitin plot gjallëri të 2016-ës, një yll i ri u shfaq në horizontin e industrisë së shitjes me pakicë: XharraBAU. E themeluar nga një grup sipërmarrësish të pasionuar me një dashuri të përbashkët për mjeshtërinë dhe përmirësimin e shtëpisë, kompania vendosi të revolucionarizojë mënyrën se si njerëzit aksesojnë dhe përdorin mjetet profesionale dhe shtëpiake.
                                <br />
                                <br />
                                Fillimet e Përulura
                                <br />
                                <br />
                                XharraBAU filloi si një vitrinë modeste në një qytet të vogël, duke ofruar një gamë të kufizuar mjetesh të zgjedhura me dorë të njohur për cilësinë dhe qëndrueshmërinë e tyre. Themeluesit, besuan në fuqinë e inovacionit dhe kënaqësisë së klientit. Ata kishin një mision të thjeshtë: t'i siguronin çdo entuziast, tregtar dhe pronar shtëpie mjetet e nevojshme për të sjellë në jetë projektet e tyre.
                                <br />
                                <br />
                                Ndërtimi i një reputacioni për ekselencë
                                <br />
                                <br />
                                Që nga dita e parë, XharraBAU ishte e përkushtuar të siguronte dhe ofronte vetëm mjetet më të mira në dispozicion. Ata krijuan marrëdhënie të forta me prodhuesit e njohur për mjeshtërinë e tyre, duke siguruar që çdo produkt të përmbushte standarde rigoroze të cilësisë.

                            </p>
                            <div className='d-flex align-items-center readmorebtn'>
                                <button className='' >Read More</button>
                                <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                            <div className='d-flex align-items-center readless' >
                                <button  >Read Less</button >
                                <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='col-md-6 bgcolorwhite p-5'>
                    <div className='p-md-5'>
                        <img className='mx-auto w-75'  src={cntcfirstimg2} />
                    </div>
                </div>
                <div className='col-md-6 bgcolorwhite p-5'>
                    
                <div className='p-md-5'>
                        <img className='mx-auto w-75'  src={cntcfirstimg} />
                    </div>
                </div>
                <div className='col-md-6 bgcolordark p-5'>

                    <div className='p-md-5'>
                        <div className='pe-md-5'>
                            <div className='d-flex align-items-center'>
                                <span className='borderline'></span>
                                <h1 className='ms-3'>Historiku</h1>
                            </div>
                            <p className='paragraphforabout pe-md-5 mt-4'>
                                Në vitin plot gjallëri të 2016-ës, një yll i ri u shfaq në horizontin e industrisë së shitjes me pakicë: XharraBAU. E themeluar nga një grup sipërmarrësish të pasionuar me një dashuri të përbashkët për mjeshtërinë dhe përmirësimin e shtëpisë, kompania vendosi të revolucionarizojë mënyrën se si njerëzit aksesojnë dhe përdorin mjetet profesionale dhe shtëpiake.
                                <br />
                                <br />
                                Fillimet e Përulura
                                <br />
                                <br />
                                XharraBAU filloi si një vitrinë modeste në një qytet të vogël, duke ofruar një gamë të kufizuar mjetesh të zgjedhura me dorë të njohur për cilësinë dhe qëndrueshmërinë e tyre. Themeluesit, besuan në fuqinë e inovacionit dhe kënaqësisë së klientit. Ata kishin një mision të thjeshtë: t'i siguronin çdo entuziast, tregtar dhe pronar shtëpie mjetet e nevojshme për të sjellë në jetë projektet e tyre.
                                <br />
                                <br />
                                Ndërtimi i një reputacioni për ekselencë
                                <br />
                                <br />
                                Që nga dita e parë, XharraBAU ishte e përkushtuar të siguronte dhe ofronte vetëm mjetet më të mira në dispozicion. Ata krijuan marrëdhënie të forta me prodhuesit e njohur për mjeshtërinë e tyre, duke siguruar që çdo produkt të përmbushte standarde rigoroze të cilësisë.

                            </p>
                            <div className='d-flex align-items-center readmorebtn'>
                                <button className='' >Read More</button>
                                <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                            <div className='d-flex align-items-center readless' >
                                <button  >Read Less</button >
                                <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1towers