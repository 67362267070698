import './App.css';
import Aboutus from './components/Aboutus';
import Cards from './components/Cards';
import Carousell from './components/Carousell';
import Footer from './components/Footer';
import Header from './components/Header';

function App() {
  return (
    <div className="App">
      <div>
        <Header />
      </div>
      <div>
        <Carousell />
      </div>
      <div>
        <Cards />
      </div>
      <div className='aboutusbg'>
        <Aboutus />
      </div>
      <div>
        <Footer />
      </div>
   
    </div>
  );
}

export default App;
