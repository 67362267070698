import React from 'react'
import xharralogo from '../images/logoxharragroup.png'

function Footer() {
    return (
        <div className='footerbx' >
            <footer class=" rounded-lg" style={{backgroundColor:'#282C34 !important'}}>
                <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div class="sm:flex sm:items-center sm:justify-between px-md-5">
                        <a href="https://flowbite.com/" class="flex items-center  sm:mb-0 justify-content-center">
                            <img src={xharralogo} class="logofooter h-8 mr-3" alt="Flowbite Logo" />
                        </a>
                        <ul class="d-none d-md-flex ms-auto flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                            <li>
                                <a href="#" class="mr-4 hover:underline md:mr-6 " style={{color:'white !important;'}}>About</a>
                            </li>
                            <li>
                                <a href="#" class="mr-4 hover:underline md:mr-6" style={{color:'white !important;'}}>Privacy Policy</a>
                            </li>
                            <li>
                                <a href="#" class="mr-4 hover:underline md:mr-6 " style={{color:'white !important;'}}>Licensing</a>
                            </li>
                            <li>
                                <a href="#" class="hover:underline" style={{color:'white !important;'}}>Contact</a>
                            </li>
                        </ul>
                    </div>
                    <hr style={{color:'white !important;'}} />
                    <span style={{color:'white !important;'}} class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="" class="hover:underline" style={{color:'white !important;'}}>XHARRA GROUP™</a>. All Rights Reserved.</span>
                </div>
            </footer>
        </div>
    )
}

export default Footer