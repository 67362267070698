import React from 'react'
import bau from '../images/bailogo.jpg'
import plast from '../images/plastlogo.jpg'
import towers from '../images/towerslogo.jpg'
import { Link } from 'react-router-dom'

function Cards() {
    return (
        <div className='container'>
            <div className='row g-0 my-5'>
                <div className='col-12 mb-4'>
                    <h1>XHARRA GROUP</h1>
                </div>
                <div className='col-md-4  pe-md-3 px-3 px-md-0'>
                    <div class=" max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <a href="#">
                            <img class="rounded-t-lg" src={bau} alt="" />
                        </a>
                        <div class="p-4">
                            <a href="#">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Xharra Bau</h5>
                            </a>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Xharra Bau përmban një gamë të gjerë të artikujve të ndërtimtarisë në bashkpunim me ndërmarrje të ndryshme.</p>
                           <Link to={'/xharrabau'}>
                           <a href="" class="btn-colorforgroup inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Më Shumë
                                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                </svg>
                            </a>
                           </Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 mt-md-0 mt-4 pe-md-3 px-3 px-md-0'>
                    <div class=" max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <a href="#">
                            <img class="rounded-t-lg" src={plast} alt="" />
                        </a>
                        <div class="p-4">
                            <a href="#">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Xharra Plast</h5>
                            </a>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Xharra Towers është një projekt me lokacion shumë të favorshëm në zemrën e qytetit të Prizren.</p>
                            <Link to={'/xharraplast'}>
                           <a href="" class="btn-colorforgroup inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Më Shumë
                                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                </svg>
                            </a>
                           </Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 mt-md-0 mt-4 pe-md-3 px-3 px-md-0'>
                    <div class=" max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <a href="#">
                            <img class="rounded-t-lg" src={towers} alt="" />
                        </a>
                        <div class="p-4">
                            <a href="#">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Xharra Towers</h5>
                            </a>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Xharra Towers është një projekt me lokacion shumë të favorshëm në zemrën e qytetit të Prizren.</p>
                            <Link to={'/xharratowers'}>
                           <a href="" class="btn-colorforgroup inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Më Shumë
                                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                </svg>
                            </a>
                           </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cards