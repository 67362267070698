import React from 'react'
import Aboutus from '../Aboutus';
import Cards from '../Cards';
import Carousell from '../Carousell';
import Footer from '../Footer';
import Header from '../Header';
import Section1towers from './towers/Section1towers';

function Xhtowers() {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <Carousell />
      </div>
      <div>
        <Section1towers />
      </div>
      <div className='mt-2'>
        <Footer />
      </div>
    </div>
  )
}

export default Xhtowers