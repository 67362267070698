import React from 'react'
import Section1plast from './plast/Section1plast'
import Carousell from '../Carousell';
import Footer from '../Footer';
import Header from '../Header';

function Xhplast() {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <Carousell />
      </div>
      <div>
        <Section1plast />
      </div>
      <div className='mt-2'>
        <Footer />
      </div>
    </div>
  )
}

export default Xhplast