import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import certificate1 from '../../../images/IZOLINE (3)-1.png'
import certificate2 from '../../../images/IZOLINE (3)-2.png'
import certificate3 from '../../../images/IZOLINE (3)-3.png'
import certificate4 from '../../../images/IZOLINE (3)-4.png'
import certificate5 from '../../../images/IZOLINE (3)-5.png'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';


// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';

export default function App() {
  return (
    <>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={certificate1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={certificate2} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={certificate3} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={certificate4} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={certificate5} />
        </SwiperSlide>
      
      </Swiper>
    </>
  );
}
