import React, { useState } from 'react'
import cntcfirstimg from '../../../images/cont.jpg'
import cntcfirstimg2 from '../../../images/bau2.jpg'

function Section1bau() {

    const [showMore, setShowMore] = useState(false);
    const [showLess, setshowLess] = useState(true);
    const [showMoree, setShowMoree] = useState(false);
    const [showLesss, setshowLesss] = useState(true);

    const toggleshowMore = () => {
        setShowMore(true);
        setshowLess(false)
    };
    const toggleshowLess = () => {
        setShowMore(false);
        setshowLess(true)
    };
    const toggleshowMoree = () => {
        setShowMoree(true);
        setshowLesss(false)
    };
    const toggleshowLesss = () => {
        setShowMoree(false);
        setshowLesss(true)
    };
    return (
        <div className=''>
            <div className='row g-0'>
                <div className='col-md-6 bgcolordark p-5'>

                    <div className='p-md-5'>
                        <div className='pe-md-5'>
                            <div className='d-flex align-items-center'>
                                <span className='borderline'></span>
                                <h1 className='ms-3'>Historiku</h1>
                            </div>
                            <p className='paragraphforabout pe-md-5 mt-4'>
                                XharraBAU: Mos leni shtëpinë për pak
                                Në vitin plot gjallëri të 2016-ës, një yll i ri u shfaq në horizontin e industrisë së shitjes me pakicë: XharraBAU. E themeluar nga një grup sipërmarrësish të pasionuar me një dashuri të përbashkët për mjeshtërinë dhe përmirësimin e shtëpisë, kompania vendosi të revolucionarizojë mënyrën se si njerëzit aksesojnë dhe përdorin mjetet profesionale dhe shtëpiake.
                                <br />
                                <br />
                                Fillimet e Përulura<br />
                                XharraBAU filloi si një vitrinë modeste në një qytet të vogël, duke ofruar një gamë të kufizuar mjetesh të zgjedhura me dorë të njohur për cilësinë dhe qëndrueshmërinë e tyre. Themeluesit, besuan në fuqinë e inovacionit dhe kënaqësisë së klientit. Ata kishin një mision të thjeshtë: t'i siguronin çdo entuziast, tregtar dhe pronar shtëpie mjetet e nevojshme për të sjellë në jetë projektet e tyre.
                                <br />
                                <br />
                                Ndërtimi i një reputacioni për ekselencë<br />
                                Që nga dita e parë, XharraBAU ishte e përkushtuar të siguronte dhe ofronte vetëm mjetet më të mira në dispozicion. Ata krijuan marrëdhënie të forta me prodhuesit e njohur për mjeshtërinë e tyre, duke siguruar që çdo produkt të përmbushte standarde rigoroze të cilësisë. Ky angazhim për përsosmëri i dha shpejt XharraBAU një reputacion si një furnizues i besueshëm i mjeteve profesionale dhe shtëpiake.
                                <br />
                                <br />


                                {showMore && !showLess && (
                                    <div>
                                        Zgjerimi i horizonteve<br />
                                        Me përhapjen e fjalës për përkushtimin e XharraBAU ndaj cilësisë dhe shërbimit ndaj klientit, kërkesa për produktet e tyre u rrit. Kompania u përgjigj duke zgjeruar gamën e produkteve të saj për të përfshirë gjithçka, nga veglat elektrike dhe veglat e dorës deri te pajisjet e kopshtarisë dhe pajisjet e sigurisë. Ky diversifikim i lejoi ata të kujdeseshin për një audiencë edhe më të gjerë.
                                        <br />
                                        <br />
                                        Inovacioni dhe Qëndrueshmëria <br />
                                        XharraBAU nuk mjaftohej vetëm me shitjen e mjeteve; synonin të ndikonin pozitivisht edhe në mjedis. Në vitin 2020, ata prezantuan një linjë mjetesh miqësore me mjedisin dhe adoptuan praktika të qëndrueshme në operacionet e tyre. Ata gjithashtu investuan në kërkime dhe zhvillim, duke kërkuar vazhdimisht zgjidhje inovative për të përmbushur nevojat në zhvillim të klientëve të tyre.
                                        Nga fillimet e tyre modeste në 2016 deri në pozicionin e tyre aktual si lider në industri, udhëtimi i XharraBAU është një testament i fuqisë së pasionit, përkushtimit dhe një kërkimi të pamëshirshëm të përsosmërisë. Ndërsa shikojnë drejt së ardhmes, angazhimi i tyre ndaj inovacionit dhe cilësisë mbetet i palëkundur, duke siguruar që ata të vazhdojnë të jenë një emër i besuar në botën e mjeteve për brezat që vijnë.
                                    </div>
                                )}

                            </p>
                            {!showMore && (
                                < div >
                                    <div className='d-flex align-items-center readmorebtn'>
                                        <button className='' onClick={toggleshowMore} >Read More</button>
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div></div>
                            )}

                            {showMore && (
                                < div >
                                    <div className='d-flex align-items-center readless' >
                                        <button onClick={toggleshowLess}>Read Less</button >
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                        </svg>
                                    </div></div>
                            )}
                        </div>
                    </div>

                </div>
                <div className='col-md-6 bgcolorwhite p-5'>
                    <div className='p-md-5'>
                        <img className='mx-auto w-75' src={cntcfirstimg} />
                    </div>
                </div>
                <div className='col-md-6 bgcolorwhite p-5'>

                    <div className='p-md-5'>
                        <img className='mx-auto w-75' src={cntcfirstimg2} />
                    </div>
                </div>
                <div className='col-md-6 bgcolordark p-5'>

                    <div className='p-md-5'>
                        <div className='pe-md-5'>
                            <div className='d-flex align-items-center'>
                                <span className='borderline'></span>
                                <h1 className='ms-3'>Për Ne</h1>
                            </div>
                            <p className='paragraphforabout pe-md-5 mt-4'>
                                "Xharra BAU" - Përmban një gamë të gjërë të artikujve të ndërtimtaris në bashkëpunim me ndërmarrje të profileve të ndryshme. Xharra BAU posedon hapsirë të përshtatshme për zhvillimin e afarizmit në një sipërfaqe mbi 3000m2.
                                <br />
                                <br />
                                Ajo përfshin shitjen e produkteve nga: - Sanitaria - Ujësjellësi - Elektrika - Vegla Pune Profesionale - Ndërtimtaria - Ngjyra - Kopshtaria - Ventilimi - Fasada - Aksesori - Pajisje shtëpiake - Higjiene ...
                                Sanitari: Dush Kabina, WC Guaca, Lavaman, Komoda per Banjo, etj.  <br />
                                <br />
                                Ujësjellësi: Gypa, Lidhese, Brryla, Sifona, Hidrofor, etj. <br />
                                <br />
                                Elektrika: Siguresa, LED, Poqa, Kabllo, Adapter, Trafo, etj. <br />
                                <br />
                                Vegla Pune Profesionale: Bosch, Makita, Rubi, STIHL, Einhell, Milwaukee etj. <br />
                                <br />
                                Ndertimataria: Llaq, Mallter i gatshem, Stiropor, Profila, Rrjeta, etj. <br />
                                <br />
                                Ngjyra: Polikolor, Dekor, Ngjyra te jashtme, Ngjyra te brendshme, Brusha, Valak, etj. <br />
                                <br />
                                Kopshtaria: Lopata, Sterpikese, Dhe për lule, Gypa Uji etj. <br />
                                <br />



                                {showMoree && !showLesss && (
                                    <div>
                                        Aksesor: Vida, Bulona, Qelesa, Dry, etj. <br />
                                        <br />
                                        Pajisje Shtepiake: Kuzhine, Dekorime, Korniza, Thithese Elektrike, Mikser, etj. <br />
                                        <br />
                                        Higjiene: Brusha, Fshisa, Gjoker, Shampo, Dezinfektues, etj. <br />
                                    </div>
                                )}
                            </p>
                            {!showMoree && (
                                < div >
                                    <div className='d-flex align-items-center readmorebtn'>
                                        <button className='' onClick={toggleshowMoree} >Read More</button>
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div></div>
                            )}

                            {showMoree && (
                                < div >
                                    <div className='d-flex align-items-center readless' >
                                        <button onClick={toggleshowLesss}>Read Less</button >
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                        </svg>
                                    </div></div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1bau