import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App.js'
import Xhtowers from './components/Pages/Xhtowers.js'
import Xhbau from './components/Pages/Xhbau.js';
import Xhplast from './components/Pages/Xhplast.js';
import Izoline from './components/Pages/Izoline.js';
import Contact from './components/Pages/Contact.js';

const Routesapp = () => {
  return (
    <BrowserRouter> 
    <Routes> 
      <Route path="/" element={<App />} /> 
      <Route path="/xharratowers" element={<Xhtowers />} /> 
      <Route path="/xharraplast" element={<Xhplast />} /> 
      <Route path="/xharrabau" element={<Xhbau />} /> 
      <Route path="/izolineks" element={<Izoline />} /> 
      <Route path="/contact" element={<Contact />} /> 
    </Routes> 
  </BrowserRouter> 
  );
}

export default Routesapp;
