import React from 'react'
import Section1bau from './bau/Section1bau'
import Carousell from '../Carousell';
import Footer from '../Footer';
import Header from '../Header';
import Xhizolineks from './izoline/Xhizolineks';
import Swiper from './izoline/Swiper.js'

function Izoline() {
  return (
    <div>
    <div>
      <Header />
    </div>
    <div>
      <Carousell />
    </div>
    <div>
      <Xhizolineks />
    </div>
    <div className='py-5 my-md-5'>
      <Swiper />
    </div>
    <div className='mt-2'>
      <Footer />
    </div>
  </div>
  )
}

export default Izoline