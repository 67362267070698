import Carousel from 'react-bootstrap/Carousel';
import img1 from '../images/5ph.jpg'
import img2 from '../images/bannerbau.png'
import img3 from '../images/bannerplast.jpg'
import img5 from '../images/Web-Banner-Izoline.jpg'
import img6 from '../images/Web-Banner-Xharra.jpg'
import img7 from '../images/towersmobil.jpg'
import img8 from '../images/Web-Banner-Izolinemobil.jpg'
import img9 from '../images/Web-Banner-Xharramobil.jpg'
import img10 from '../images/bannerbaumobil.png'

function Carousell() {
  return (
    <Carousel>
      <Carousel.Item>
        <img className='h-80 imagesincarousel d-none d-md-block' src={img6} />
        <img className=' imagesincarousel d-md-none d-block imagicarouselheighmobile' src={img7} />
      </Carousel.Item>
      <Carousel.Item>
        <img className='h-80 imagesincarousel d-none d-md-block' src={img5} />
        <img className='imagesincarousel d-md-none d-block imagicarouselheighmobile' src={img8} />
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <img className='h-80 imagesincarousel d-none d-md-block'  src={img1} />
        <img className='imagesincarousel d-md-none d-block imagicarouselheighmobile' src={img9} />
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img className='h-80 imagesincarousel d-none d-md-block' src={img2} />
        <img className=' imagesincarousel d-md-none d-block imagicarouselheighmobile' src={img10} />
      </Carousel.Item>
    </Carousel>
  );
}

export default Carousell;