import React, { useState } from 'react'
import cntcfirstimg1 from '../../../images/izoline2.jpg'
import izolineimg from '../../../images/izoline.png'

function Xhizolineks() {
    const [showMore, setShowMore] = useState(false);
    const [showLess, setshowLess] = useState(true);

    const toggleshowMore = () => {
        setShowMore(true);
        setshowLess(false)
    };
    const toggleshowLess = () => {
        setShowMore(false);
        setshowLess(true)
    };
    return (
        <div className=''>
            <div className='row g-0'>
                <div className='col-md-6 bgcolordark p-5'>
                    <div className='p-md-5'>
                        <div className='pe-md-5'>
                            <div className='d-flex align-items-center'>
                                <span className='borderline'></span>
                                <h1 className='ms-3'>Historiku</h1>
                            </div>
                            <p className='paragraphforabout pe-md-5 mt-4'>
                                Izoline është një kompani prodhuese evropiane e fokusuar në ofrimin e sistemeve të çatisë dhe
                                hidroizolimit, si dhe aksesorëve thelbësorë për çdo projekt. Produktet e Izoline shiten në të gjithë
                                Evropën. Në vitin 2018, ne vendosëm të sjellim produktet tona të projektuara evropiane në Amerikën e
                                Veriut. Në një ndërhapje të ndryshimeve më të reja të kompanisë, Izoline ka hapur një dege të re në
                                SHBA, e quajtur "Izoline North America."
                                <br />
                                <br />
                                Ky hapje është një tjetër shembull i angazhimit të Izoline për
                                t'u rritur dhe për të ofruar shërbime të shkëlqyera në tregun amerikan. Ky hapësirë është e projektuar
                                për të përgjigjur më mirë nevojat e klientëve tanë në Kosovë, Europe dhe SHBA për të ndihmuar në
                                ndërtimin e një të ardhme të ndritshme në këtë treg të rëndësishëm.
                                <br />
                                <br />
                                Izoline KS ofron gjashte lloje te produkteve hidroizoluese, gomave sintetike ne forme primare dhe
                                izolatoreve prej qeramike, te gjitha sipas standardeve nderkombetare.
                                Nese ju ose dikush qe njihni merret me industrine e çative te shtepive si dhe izolimin e ndertesave, mos
                                hezitoni te me kontaktoni ne kete email adrese: info@izolinenorthamerica.com
                                Jemi NE ata qe do e zhvillojme Kosoven! Ju falenderoj per besimin tuaj!
                            </p>
                          
                        </div>
                    </div>

                </div>
                <div className='col-md-6 bgcolorwhite p-5'>
                    <div className='p-md-5'>
                        <img className='mx-auto w-75' src={cntcfirstimg1} />
                    </div>
                </div>
                <div className='col-md-6 bgcolorwhite p-5'>

                    <div className='p-md-5'>
                        <img className='mx-auto w-75' src={izolineimg} />
                    </div>
                </div>
                <div className='col-md-6 bgcolordark p-5'>
                    <div className='p-md-5'>
                        <div className='pe-md-5'>
                            <div className='d-flex align-items-center'>
                                <span className='borderline'></span>
                                <h1 className='ms-3'>Për Ne</h1>
                            </div>
                            <p className='paragraphforabout pe-md-5 mt-4'>
                                “Përparim i vazhdueshëm dhe rritje ndërkombëtare në industrinë e Izolimit"
                                Me një histori të gjatë të suksesit dhe një ekip të përkushtuar të inxhinierëve dhe profesionistëve të tjera, Izoline është një
                                kompani lider në prodhimin e membranave izoluese. Produktet tona janë të njohura për cilësinë e lartë, rezistencën ndaj kushteve
                                të ndryshme klimatike, dhe përshtatshmërinë për shumë lloje të aplikacioneve ndërtimore. Izoline, është një kompani e njohur në
                                industrinë e prodhimit të membranave izoluese të cilat janë një përbërës kyç për ndërtimin e qëndrueshëm dhe të cilësisë së
                                lartë.
                                <br />
                                <br />
                                E themeluar dhe e drejtuar nga Xharra Group, Izoline ka bërë një udhëtim të jashtëzakonshëm që nga krijimi i saj dhe tani është e
                                njohur si një lider i tregut në prodhimin e izolimeve të çdo lloji sipas standardeve ndërkombëtare. Izoline është bërë emër në
                                tregun ndërkombëtar për shkak të përkushtimit të saj të vazhdueshëm për cilësinë dhe inovacionin. Një nga sukseset më të
                                mëdha të kompanisë është eksportimi në SHBA.
                                <br />
                                <br />
                                Në Izoline, jemi të vendosur të vazhdojmë të inovojmë dhe të ofrojmë zgjidhje të avancuara për ndërtuesit dhe arkitektët në të
                                gjithë botën. Me një histori të pasur të eksperiencës dhe suksesit në eksport, si dhe me hapjen e Izoline North America, jemi të
                                gatshëm për të shërbyer tregun e SHBA-së dhe për të arritur suksesin e vazhdueshëm në tregjet ndërkombëtare. Izoline është e
                                gatshme për të përcjellë cilësinë dhe inovacionin në secilin projekt të ndërtimit, dhe jemi krenarë që kemi bërë një hap tjetër në
                                udhën tonë drejt shpërblimeve të mëdha në industrinë e izolimit.
                                <br />
                                <br />

                                {showMore && !showLess && (
                                    <div>
                                        Nga produktet më të shitura janë:<br />
                                        1 IZO 50/PS18/GR/GRAY/7.5m<br />
                                        2 IZO 50/PS15/10m<br />
                                        3 IZO 50/ALUMIN/10m<br />
                                        4 IZO 35/PS15/10m<br />
                                        5 IZO 20/RRQ/7.5m<br />
                                        6 IZO 20/RRQ/10m<br />
                                        7 ECO 40/RRQ/10m<br />
                                        8 IZOBIT 4kg (BITOLIT)<br />
                                        9 IZOBIT 15kg (BITOLIT)
                                    </div>
                                )}
                            </p>


                            {!showMore && (
                                < div >
                                    <div className='d-flex align-items-center readmorebtn'>
                                        <button className='' onClick={toggleshowMore} >Read More</button>
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div></div>
                            )}

                            {showMore && (
                                < div >
                                    <div className='d-flex align-items-center readless' >
                                        <button onClick={toggleshowLess}>Read Less</button >
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                        </svg>
                                    </div></div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Xhizolineks