import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import '../contact.css'

function Contact() {
    return (
        <div>
        <div>
          <Header />
        </div>
        <div>
        <div class="section2contact py-5 ">
    <div class="container py-md-5">
        <div class="row g-3 align-items-center">
            <div class="col-md-6">
                <div class="">
                    <div>
                        <h1><strong>Our Location to Head Quarters</strong></h1>
                        <p class="mb-4 lightgreycolor">Rr. Barikadave p.n, Prizren<br/>
                        Rr. Barikadave N.N. 20000 Prizren, Kosove<br/>
                        Rr. Nënë Tereza nr 294, Magjistralja Prishtinë - Fushë Kosovë<br/>
                        Rr. Magjistralja Prishtine-Ferizaj, p.n., 70 000 Ferizaj<br/>
                        Rr.Egnatia, pranë Tirana Industrial Park, Kashar <br/>
                        </p>
                    </div>
                    <div>
                        <div class="mb-5">
                            <div class="d-flex align-items-center">
                                <div class="iconswrapper me-3" style={{backgroundColor: '#0E0E0E', color:'#DFFF88'}}><i class="bi bi-newspaper"></i></div>
                                <div>
                                    <h5 class="mb-1"><strong>Call our support center</strong></h5>
                                    <span class="lightgreycolor">+383 44 504 693<br/>
                                    +383 44 389 503<br/>
                                    +383 44 641 525<br/>
                                    +383 45 210 001<br/>
                                    +355 69 408 6433<br/></span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <div class="d-flex align-items-center">
                                <div class="iconswrapper me-3" style={{backgroundColor: '#0E0E0E', color:'#DFFF88'}}><i class="bi bi-newspaper"></i></div>
                                <div>
                                    <h5 class="mb-1"><strong>Mail us</strong></h5>
                                    <span class="lightgreycolor">
                                    xharra.pz@gmail.com<br/>
                                    xharra.pr1@gmail.com<br/>
                                    xharra.ferizaj@gmail.com<br/>
                                    info.ks@izolinenorthamerica.com<br/>
                                    xharra.al@gmail.com <br/>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <div class="d-flex align-items-center">
                                <div class="iconswrapper me-3" style={{backgroundColor: '#0E0E0E', color:'#DFFF88'}}><i class="bi bi-newspaper"></i></div>
                                <div>
                                    <h5 class="mb-1"><strong>Chat with support team</strong></h5>
                                    <span class="lightgreycolor">link can be found below</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p><strong>You can check out <span style={{color:'#DFFF88'}}>FAQ</span> to get more
                                    insights on our product.</strong></p>
                        </div>
                        <div>
                            <div class="d-flex align-items-center">
                                <i class="bi bi-facebook"></i>
                                <i class="ms-3 bi bi-linkedin"></i>
                                <i class="ms-3 bi bi-twitter"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <div class="contactbackground">
                        <div class="p-md-5 p-3">
                            <div class="mb-3">
                                <h4 className='text-white'>Contact Us</h4>
                            </div>
                            <div>
                                <div>
                                    <input class="inputfields" type="text" placeholder="First name" />
                                    <input class="inputfields ms-1" type="text" placeholder="Last name" />
                                </div>
                                <div class="my-4">
                                    <input class="inputfields3" type="email" placeholder="Email" />
                                    <input class="inputfields3 ms-1" type="email" placeholder="How can we help you?" />
                                </div>
                                <div>
                                    <textarea class="inputfields2" name="" id="" cols="20" rows="7" placeholder="Comment"></textarea>
                                </div>
                                <div class="mt-3">
                                    <button class="getstartedbutton" style={{backgroundColor:'white'}}>Send Message</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        </div>
        <div className='mt-2'>
          <Footer />
        </div>
      </div>
            )
}

            export default Contact