import React from 'react'
import Section1bau from './bau/Section1bau'
import Carousell from '../Carousell';
import Footer from '../Footer';
import Header from '../Header';

function Xhbau() {
  return (
    <div>
    <div>
      <Header />
    </div>
    <div>
      <Carousell />
    </div>
    <div>
      <Section1bau />
    </div>
    <div className='mt-2'>
      <Footer />
    </div>
  </div>
  )
}

export default Xhbau